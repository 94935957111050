import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import JSLogo from "../../images/logo192.png";
import CalendlyWidget from "../calendly/calendly";

function VideoRecording({
  spotlights,
  selectedSpotlight,
  setSelectedSpotlight,
  role,
  setRole,
  handleSaveRole,
  setSpotlightNeedUpdate,
}) {
  const [animationClass, setAnimationClass] = useState("animate-fadeInUp");
  const [deleteSpotlight, setDeleteSpotlight] = useState(null);
  const [editFormData, setEditFormData] = useState({ name: "" });
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedSoftware, setSelectedSoftware] = useState("Loom");
  const [selectedTab, setSelectedTab] = useState("Previous videos");
  const handleSpotlightChange = (spotlight) => {
    setSelectedSpotlight((prev) =>
      prev?.id === spotlight?.id ? null : spotlight
    );
    role.spotlight = spotlight;
  };

  const handleTabClick = (tabName) => {
    if (tabName === "Previous videos" && spotlights.length === 0) {
      // Prevent switching to "Previous videos" if there are no spotlights
      return;
    }
    if (tabName !== selectedTab) {
      setSelectedTab(tabName);
    }
  };

  // Initial default tab
  useEffect(() => {
    if (spotlights.length === 0 && selectedTab !== "Add a new video") {
      setSelectedTab("Add a new video");
    }
  }, [spotlights]);

  const openEditModal = (spotlight) => {
    setEditFormData({
      name: spotlight.name,
      id: spotlight.id,
    });
    setIsEditModalOpen(true);
  };

  const handleEditChange = (event) => {
    const { name, value } = event.target;
    setEditFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    const updatedData = {
      fields: {
        Name: editFormData.name,
      },
      spotlightId: editFormData.id, // Make sure the ID is included
    };

    try {
      const token = localStorage.getItem("token");
      await axios.patch("/api/spotlights/updateSpotlightData", updatedData, {
        headers: {
          Authorization: `Bearer ${token}`, // Use backticks for string interpolation
          "Content-Type": "application/json",
        },
      });
    } catch (err) {
      console.error(err); // Log the error for debugging
    }
    setSpotlightNeedUpdate(true);
    setIsEditModalOpen(false);
  };

  const handleDeleteSpotlight = async (event) => {
    event.preventDefault();
    const updatedData = {
      fields: {
        Deleted: true,
      },
      spotlightId: deleteSpotlight.id, // Make sure the ID is included
    };

    try {
      const token = localStorage.getItem("token");
      await axios.patch("/api/spotlights/updateSpotlightData", updatedData, {
        headers: {
          Authorization: `Bearer ${token}`, // Use backticks for string interpolation
          "Content-Type": "application/json",
        },
      });

      // Check if the deleted spotlight is the selected spotlight
      if (selectedSpotlight && selectedSpotlight.id === deleteSpotlight.id) {
        setSelectedSpotlight(null); // Set selectedSpotlight to null if the deleted spotlight is the selected one
      }
    } catch (err) {
      console.error(err); // Log the error for debugging
    }
    setSpotlightNeedUpdate(true);
    setDeleteSpotlight(null);
  };

  const [newSpotlight, setNewSpotlight] = useState({
    name: "",
    url_dropbox: "",
    url_loom: "",
  });

  const handleAddSpotlightChange = (event) => {
    const { name, value } = event.target;
    setNewSpotlight((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const currentMonth = new Intl.DateTimeFormat("en-US", {
    month: "long",
  }).format(new Date());

  const handleAddSpotlightSubmit = async (event) => {
    event.preventDefault();

    const data = {
      fields: {
        Name: `${role.name} - ${currentMonth}`,
      },
    };

    if (selectedSoftware === "Loom") {
      data.fields["URL Loom"] = newSpotlight.url_loom;
    } else {
      data.fields["URL Dropbox"] = newSpotlight.url_dropbox;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        "/api/spotlights/createSpotlight",
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const spotlight = response.data.newSpotlightData.fields;
      const transformedSpotlight = {
        id: spotlight["id"],
        dropbox_url: spotlight["URL Dropbox cleaned"] || null,
        loom_url: spotlight["URL Loom cleaned"] || null,
        name: spotlight["Name"],
      };

      setSelectedSpotlight(transformedSpotlight);
    } catch (err) {
      console.error(err);
    }

    setSpotlightNeedUpdate(true);
    setSelectedTab("Previous videos");
  };

  return (
    <>
      <div className={`flex flex-col -mb-20 items-left ${animationClass}`}>
        <h2 className="text-4xl -ml-14 pb-2 font-semibold">
          12. Please select a video recording.
        </h2>
        <p className="pb-4  italic">
          Startups with a recorded pitch drive 50% more engagement. It helps
          them self-select roles best suited to their skills and will save you
          time later on!
        </p>
        <div className="bg-gray-200 rounded-md flex p-1 mb-4 w-[352px] relative">
          <div
            className={` m absolute top-1 left-1 h-10 bg-white rounded-md transition-transform duration-300 ease-in-out ${
              selectedTab === "Add a video"
                ? "translate-x-full w-40 ml-6"
                : "translate-x-0  w-1/2"
            }`}
          ></div>
          <button
            onClick={() => handleTabClick("Previous videos")}
            className={`z-10 rounded-md px-4 py-2 flex justify-center items-center ${
              selectedTab === "Previous videos" ? "text-black" : "text-gray-500"
            } ${
              spotlights.length === 0 ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={spotlights.length === 0}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6 mr-2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.375 19.5h17.25m-17.25 0a1.125 1.125 0 0 1-1.125-1.125M3.375 19.5h1.5C5.496 19.5 6 18.996 6 18.375m-3.75 0V5.625m0 12.75v-1.5c0-.621.504-1.125 1.125-1.125m18.375 2.625V5.625m0 12.75c0 .621-.504 1.125-1.125 1.125m1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125m0 3.75h-1.5A1.125 1.125 0 0 1 18 18.375M20.625 4.5H3.375m17.25 0c.621 0 1.125.504 1.125 1.125M20.625 4.5h-1.5C18.504 4.5 18 5.004 18 5.625m3.75 0v1.5c0 .621-.504 1.125-1.125 1.125M3.375 4.5c-.621 0-1.125.504-1.125 1.125M3.375 4.5h1.5C5.496 4.5 6 5.004 6 5.625m-3.75 0v1.5c0 .621.504 1.125 1.125 1.125m0 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125m1.5-3.75C5.496 8.25 6 7.746 6 7.125v-1.5M4.875 8.25C5.496 8.25 6 8.754 6 9.375v1.5m0-5.25v5.25m0-5.25C6 5.004 6.504 4.5 7.125 4.5h9.75c.621 0 1.125.504 1.125 1.125m1.125 2.625h1.5m-1.5 0A1.125 1.125 0 0 1 18 7.125v-1.5m1.125 2.625c-.621 0-1.125.504-1.125 1.125v1.5m2.625-2.625c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125M18 5.625v5.25M7.125 12h9.75m-9.75 0A1.125 1.125 0 0 1 6 10.875M7.125 12C6.504 12 6 12.504 6 13.125m0-2.25C6 11.496 5.496 12 4.875 12M18 10.875c0 .621-.504 1.125-1.125 1.125M18 10.875c0 .621.504 1.125 1.125 1.125m-2.25 0c.621 0 1.125.504 1.125 1.125m-12 5.25v-5.25m0 5.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125m-12 0v-1.5c0-.621-.504-1.125-1.125-1.125M18 18.375v-5.25m0 5.25v-1.5c0-.621.504-1.125 1.125-1.125M18 13.125v1.5c0 .621.504 1.125 1.125 1.125M18 13.125c0-.621.504-1.125 1.125-1.125M6 13.125v1.5c0 .621-.504 1.125-1.125 1.125M6 13.125C6 12.504 5.496 12 4.875 12m-1.5 0h1.5m-1.5 0c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125M19.125 12h1.5m0 0c.621 0 1.125.504 1.125 1.125v1.5c0 .621-.504 1.125-1.125 1.125m-17.25 0h1.5m14.25 0h1.5"
              />
            </svg>
            Previous videos
          </button>
          <button
            onClick={() => handleTabClick("Add a video")}
            className={`z-10 rounded-md px-4 py-2 flex justify-center items-center ${
              selectedTab === "Add a video" ? "text-black" : "text-gray-500"
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-6 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 4.5v15m7.5-7.5h-15"
              />
            </svg>
            Add a video
          </button>
        </div>

        {selectedTab === "Previous videos" ? (
          <div className="flex space-x-4 w-full">
            <div className="flex-1 w-full">
              {spotlights.map((spotlight, index) => (
                <div key={index} className="space-y-2 mb-2">
                  <div
                    className={`cursor-pointer p-5 border rounded-lg ${
                      selectedSpotlight?.id === spotlight.id
                        ? "border-[#02B491] text-[#02B491] bg-[#02B491]/10"
                        : "border-gray-200 text-gray-900 bg-white hover:bg-gray-100"
                    }`}
                    onClick={() => handleSpotlightChange(spotlight)}
                  >
                    <div className="block w-[400px] relative">
                      <div className="w-full text-lg font-semibold">
                        {spotlight.name}
                      </div>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="size-6 hover:text-red-500 text-black absolute top-0 right-0"
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteSpotlight(spotlight);
                        }}
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                        />
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="size-6 hover:text-[#02B491] text-black absolute top-0 right-8"
                        onClick={(e) => {
                          e.stopPropagation();
                          openEditModal(spotlight);
                        }}
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              ))}

              <div className="col-span-full pt-4">
                <label
                  htmlFor="key_experience"
                  className="block text-md font-semibold leading-6 text-gray-900"
                >
                  Additional context
                </label>
                <div className="mt-2">
                  <textarea
                    id="spotlight_context"
                    name="spotlight_context"
                    rows="2"
                    className="block w-full pl-2 rounded-lg bg-transparent border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                    placeholder="This is a recording we did for a different role but should give a good flavour of our company!"
                    value={role.spotlight_context}
                    onChange={(e) =>
                      setRole({
                        ...role,
                        spotlight_context: e.target.value,
                      })
                    }
                  ></textarea>
                </div>
              </div>

              <div className="flex items-center gap-2">
                <button
                  onClick={() => handleSaveRole(true)}
                  className="mt-4 flex items-center text-xl font-medium py-2 px-8 bg-white border-2 border-[#02B491] rounded-md hover:bg-[#02B491] hover:text-white"
                >
                  Create role
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    class="size-6 ml-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="flex-1 w-full">
              {selectedSpotlight ? (
                selectedSpotlight.dropbox_url ? (
                  <iframe
                    src={selectedSpotlight.dropbox_url}
                    className="h-[300px] w-[500px] object-cover rounded-xl bg-[var(--overlay-2)]"
                    controls
                    onError={(e) => {
                      console.error("Failed to load video at", e.target.src);
                    }}
                  ></iframe>
                ) : (
                  <iframe
                    src={selectedSpotlight.loom_url}
                    className="h-[300px] w-[500px] object-cover rounded-xl bg-[var(--overlay-2)]"
                  ></iframe>
                )
              ) : (
                <div className="h-[300px] w-[500px] rounded-xl bg-gray-200 flex items-center justify-center text-2xl">
                  No video selected
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="bg-white max-w-[1000px] rounded-lg">
            <div className="flex w-full">
              {" "}
              <div className="w-[500px]">
                <p className="font-semibold text-xl">
                  How do you want to add a video?
                </p>
                <p className="mb-4 text-gray-600 mb-2">
                  Click{" "}
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        `https://jumpstart-alumni.notion.site/Very-quick-guidance-on-recording-your-startup-s-video-5ce5688a610648c89f59d9382e42c226?pvs=4`,
                        "_blank"
                      );
                    }}
                    className="text-blue-500 hover:underline cursor-pointer"
                  >
                    here
                  </span>{" "}
                  for very simple instructions
                </p>
                <div className=" mb-2 z-50">
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedSoftware("Loom");
                    }}
                    className={`mb-2 px-2 text-left flex items-center font-medium text-lg w-[300px] py-2 rounded-md border border-purple-600 ${
                      selectedSoftware === "Loom" ? "border-2 " : ""
                    } bg-purple-600/10 hover:bg-purple-600/20`}
                  >
                    <svg
                      className="size-6 mr-2 text-gray-400"
                      viewBox="0 0 16 16"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="#625DF5"
                        d="M15 7.222h-4.094l3.546-2.047-.779-1.35-3.545 2.048 2.046-3.546-1.349-.779L8.78 5.093V1H7.22v4.094L5.174 1.548l-1.348.779 2.046 3.545-3.545-2.046-.779 1.348 3.546 2.047H1v1.557h4.093l-3.545 2.047.779 1.35 3.545-2.047-2.047 3.545 1.35.779 2.046-3.546V15h1.557v-4.094l2.047 3.546 1.349-.779-2.047-3.546 3.545 2.047.779-1.349-3.545-2.046h4.093L15 7.222zm-7 2.896a2.126 2.126 0 110-4.252 2.126 2.126 0 010 4.252z"
                      />
                    </svg>
                    Loom
                    {selectedSoftware === "Loom" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        class="size-5 ml-auto text-purple-600"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="m4.5 12.75 6 6 9-13.5"
                        />
                      </svg>
                    )}
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedSoftware("Dropbox");
                    }}
                    className={`mb-2 px-2 text-left flex items-center font-medium text-lg w-[300px] py-2 rounded-md border border-blue-600 ${
                      selectedSoftware === "Dropbox" ? "border-2 " : ""
                    } bg-blue-600/10 hover:bg-blue-600/20`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="size-6 mr-2 text-gray-400"
                      viewBox="25 24.999 850 790.592"
                    >
                      <g fill="#007ee5">
                        <path d="M275.037 24.999L25 188.255l172.886 138.448L450 171.023zM25 465.16l250.037 163.256L450 482.374l-252.114-155.67zM450 482.374l174.963 146.042L875 465.16 702.114 326.703z" />
                        <path d="M875 188.255L624.963 24.999 450 171.024l252.114 155.68zM450.513 513.797l-175.476 145.61-75.09-49.028v54.959L450.513 815.59 701.08 665.338v-54.96l-75.09 49.029z" />
                      </g>
                    </svg>
                    Dropbox
                    {selectedSoftware === "Dropbox" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        class="size-5 ml-auto text-blue-600"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="m4.5 12.75 6 6 9-13.5"
                        />
                      </svg>
                    )}
                  </button>
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      setSelectedSoftware("Jumpstart");
                    }}
                    className={`mb-2 px-2 text-left flex items-center font-medium text-lg w-[300px] py-2 rounded-md border border-[#02B491] ${
                      selectedSoftware === "Jumpstart" ? "border-2 " : ""
                    } bg-[#02B491]/10 hover:bg-[#02B491]/20`}
                  >
                    <img src={JSLogo} className="size-6 mr-2" />
                    Record with Jumpstart
                    {selectedSoftware === "Jumpstart" && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        class="size-5 ml-auto text-[#02B491]"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="m4.5 12.75 6 6 9-13.5"
                        />
                      </svg>
                    )}
                  </button>
                </div>
              </div>
              <div className="w-full">
                {selectedSoftware === "Jumpstart" ? (
                  <div className="w-full">
                    <CalendlyWidget />
                  </div>
                ) : (
                  <div className="mt-20 ml-4">
                    <div className="my-4 ">
                      <label className="block text-md flex font-medium text-gray-700">
                        URL
                      </label>
                      <input
                        type="url"
                        name={`${
                          selectedSoftware === "Loom"
                            ? "url_loom"
                            : "url_dropbox"
                        }`}
                        placeholder={`${
                          selectedSoftware === "Loom"
                            ? "https://www.loom.com/share/412a3abc720e4f8e946b2633086c902d?sid=01acb4b3-2943-4330-a56c-a7e12c752339"
                            : "https://www.dropbox.com/scl/fi/v42ug5su2fa6q77exssj0/2024.08.01_Hokodo.mp4?rlkey=afdeb5863114ad0z00ygjn1uv&dl=0"
                        }`}
                        value={
                          selectedSoftware === "Loom"
                            ? newSpotlight.url_loom
                            : newSpotlight.url_dropbox
                        }
                        onChange={handleAddSpotlightChange}
                        className="max-w-[500px] block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 sm:text-md sm:leading-6"
                        required
                      />
                    </div>
                    <div className="flex space-x-2">
                      <button
                        onClick={() =>
                          window.open(
                            selectedSoftware !== "Dropbox"
                              ? "https://www.loom.com/looms/videos"
                              : "https://www.dropbox.com/home",
                            "_blank"
                          )
                        }
                        className={`${
                          selectedSoftware !== "Dropbox"
                            ? "bg-purple-600 text-white"
                            : "bg-blue-600 text-white"
                        } hover:text-black hover:bg-white border-2 px-4 py-2 rounded-md flex justify-center items-center ${
                          selectedSoftware !== "Dropbox"
                            ? "border-purple-600"
                            : "border-blue-600"
                        }`}
                      >
                        {selectedSoftware !== "Dropbox"
                          ? "Go to Loom"
                          : "Go to Dropbox"}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-5 ml-2"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25"
                          />
                        </svg>
                      </button>
                      <button
                        onClick={(e) => handleAddSpotlightSubmit(e)}
                        className="hover:bg-[#02B491] border-[#02B491] border-2 text-black hover:text-white px-4 py-2 rounded-md flex justify-center items-center"
                      >
                        Save video
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {isEditModalOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white w-[400px] p-6 rounded-lg shadow-lg">
              <h2 className="text-2xl mb-4">Edit Video Recording</h2>

              <div className="mb-4">
                <label className="block text-md font-medium text-gray-700">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={editFormData.name}
                  onChange={handleEditChange}
                  className="block pl-2 w-full rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600 sm:text-md sm:leading-6"
                  required
                />
              </div>

              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setIsEditModalOpen(false)}
                  className="mr-4 px-4 py-2 bg-gray-300 rounded-md"
                >
                  Cancel
                </button>
                <button
                  onClick={handleEditSubmit}
                  type="submit"
                  className="px-4 py-2 bg-[#02B491] text-white rounded-md"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}

        {deleteSpotlight && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white w-[400px] p-6 rounded-lg shadow-lg">
              <h2 className="text-2xl font-medium mb-4">Delete Spotlight</h2>
              <h2 className="text-lg mb-4">
                Are you sure you want to delete this spotlight?
              </h2>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setDeleteSpotlight(null)}
                  className="mr-4 px-4 py-2 bg-gray-300 rounded-md"
                >
                  Cancel
                </button>
                <button
                  onClick={handleDeleteSpotlight}
                  className="px-4 py-2 bg-red-500 text-white rounded-md"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default VideoRecording;
