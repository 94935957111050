import React, { useEffect, useState } from "react";
import linkedin from "../../images/linkedin-logo.png";
import cv from "../../images/cv-icon.png";
import github from "../../images/github.png";
import Office from "../../images/office-icon.png";
import Languages from "../../images/languages-icon.png";
import VideoIntro from "../../images/video-icon-green.png";
import LoadingBlack from "../../images/loading-black.svg"; // Ensure you have the loading.svg in the correct path

function ShortlistCard({
  handleAddSelectedCandidate,
  handleRemoveSelectedCandidate,
  selectedCandidates,
  candidate,
  onToggleCandidate,
  handleRemoveCandidate,
  role,
  removing,
}) {
  const getColorClasses = (stream) => {
    switch (stream) {
      case "Generalist":
        return {
          bgColor: "bg-emerald-100",
          textColor: "text-emerald-700",
          workBgColor: "bg-emerald-50",
          fillColor: "fill-emerald-700",
          bgColor2: "bg-emerald-400",
        };
      case "Sales":
        return {
          bgColor: "bg-blue-100",
          textColor: "text-blue-700",
          workBgColor: "bg-blue-50",
          fillColor: "fill-blue-700",
          bgColor2: "bg-blue-400",
        };
      case "Software Engineering":
        return {
          bgColor: "bg-orange-100",
          textColor: "text-orange-700",
          workBgColor: "bg-orange-50",
          fillColor: "fill-orange-700",
          bgColor2: "bg-orange-400",
        };
      case "Marketing":
        return {
          bgColor: "bg-purple-100",
          textColor: "text-purple-700",
          workBgColor: "bg-purple-50",
          fillColor: "fill-purple-700",
          bgColor2: "bg-purple-400",
        };
      default:
        return {
          bgColor: "bg-gray-100",
          textColor: "text-gray-700",
          workBgColor: "bg-gray-50",
          fillColor: "fill-gray-700",
          bgColor2: "bg-gray-400",
        };
    }
  };


  const formatDuration = (duration) => {
    if (!duration) return "";
    
    const years = Number(duration.years) || 0;
    const months = Number(duration.months) || 0;
    
    // Convert excess months to years
    const totalYears = years + Math.floor(months / 12);
    const remainingMonths = months % 12;
    
    const parts = [];
    if (totalYears > 0) {
      parts.push(`${totalYears} yrs`);
    }
    if (remainingMonths > 0) {
      parts.push(`${remainingMonths} mos`);
    }
    
    return parts.length > 0 ? parts.join(', ') : '';
  };
  
  const [companyLink, setCompanyLink] = useState("");
  useEffect(() => {
    const linkFromStorage = localStorage.getItem("companyLink");

    setCompanyLink(linkFromStorage);
  }, []);

  const { bgColor, textColor, workBgColor, fillColor, bgColor2 } =
    getColorClasses(candidate.stream);

  const fallbackImageUrl =
    "https://cdn-icons-png.flaticon.com/512/4120/4120345.png";

  const isSelected = selectedCandidates.includes(candidate.id);

  const handleSelectToggle = () => {
    if (isSelected) {
      handleRemoveSelectedCandidate(role, candidate.id);
    } else {
      handleAddSelectedCandidate(role, candidate.id);
    }
  };

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const linkFromStorage = localStorage.getItem("companyLink");
    setCompanyLink(linkFromStorage);
  }, []);

  const copyToClipboard = () => {
    const link = `${companyLink}&page=candidate/${candidate.url_id}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };

  const workingStyleMapping = Array.isArray(candidate.working_style)
    ? candidate.working_style.map((style) => {
        if (style === "5 days a week in office") {
          return "Fully in office";
        } else if (style === "Hybrid (1-4 days in office)") {
          return "Hybrid";
        } else if (style === "Fully remote") {
          return "Fully remote";
        }
        return style;
      })
    : [];

  return (
    <div className="relative w-full h-full">
      {candidate.featured !== "Featured" && (
        <div className="absolute w-[600px] top-0 left-0 p-4 rounded-md z-40 h-full bg-black bg-opacity-70 flex flex-col justify-center items-center">
          <div className="bg-white p-3 flex rounded-md w-4/5 text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="2.5"
              stroke="currentColor"
              class="size-6 font-bold"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m3 3 1.664 1.664M21 21l-1.5-1.5m-5.485-1.242L12 17.25 4.5 21V8.742m.164-4.078a2.15 2.15 0 0 1 1.743-1.342 48.507 48.507 0 0 1 11.186 0c1.1.128 1.907 1.077 1.907 2.185V19.5M4.664 4.664 19.5 19.5"
              />
            </svg>

            <p className="text-black font-bold">
              Sorry, this candidate is no longer available
            </p>
          </div>
          <button
            onClick={() => handleRemoveCandidate(role, candidate.id)}
            className="px-8 py-2 w-4/5 mt-4 rounded-md bg-white text-black font-bold transition duration-200 hover:bg-gray-400 hover:text-white border-2 border-gray-400"
          >
            Remove
          </button>
        </div>
      )}
      <div
        className={`${
          isSelected ? "border-4 border-[#02B491]" : "border-4 border-white"
        } bg-white w-80 rounded-t-md z-30  -mb-2 mt-5 py-2 flex`}
      >
        <div className="flex items-center mt-1 justify-between gap-x-2 cursor-pointer">
          <div
            onClick={handleSelectToggle}
            className="pl-4 flex justify-center gap-x-2 items-center"
          >
            <button
              className={`rounded-md items-center justify-center flex border-2 border-[#02B491] w-10 h-10 ${
                isSelected && "bg-[#02B491]/20"
              }`}
            >
              {isSelected && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-8 text-[#02B491]"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              )}
            </button>
            <p className="text-lg">Select</p>
          </div>
          <button
            onClick={copyToClipboard}
            className="hover:bg-gray-200 text-sm rounded-md p-2 flex items-center"
          >
            {copied ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-5 mr-1"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M4.5 12.75l6 6 9-13.5"
                  />
                </svg>
                Copied
              </>
            ) : (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-5 mr-1"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M16.5 8.25V6a2.25 2.25 0 0 0-2.25-2.25H6A2.25 2.25 0 0 0 3.75 6v8.25A2.25 2.25 0 0 0 6 16.5h2.25m8.25-8.25H18a2.25 2.25 0 0 1 2.25 2.25V18A2.25 2.25 0 0 1 18 20.25h-7.5A2.25 2.25 0 0 1 8.25 18v-1.5m8.25-8.25h-6a2.25 2.25 0 0 0-2.25 2.25v6"
                  />
                </svg>
                Copy
              </>
            )}
          </button>
          <button
            className="hover:bg-gray-200 text-sm rounded-md p-2 flex items-center"
            onClick={() => handleRemoveCandidate(role, candidate.id)}
            disabled={removing === candidate.id} // Disable button while removing
          >
            {removing === candidate.id ? ( // Show loading animation only for the removing card
              <>
                <img
                  src={LoadingBlack}
                  className="h-6 fill-red-500"
                  alt="Loading"
                />
              </>
            ) : (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-5 mr-1"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>
                Remove
              </>
            )}
          </button>
        </div>
      </div>
      <div className="absolute z-30 w-[312px] ml-1 border border-4 border-white"></div>
      <div
        className={` w-[600px] z-0 mx-auto px-4 pt-2 pb-4 rounded-lg bg-white overflow-hidden flex h-full ${
          isSelected ? "border-4 border-[#02B491]" : "border-4 border-white"
        }`}
      >
        <div>
          <div className=" flex gap-x-2">
            <div className="w-32 h-32">
              <img
                className="w-full h-32 object-cover rounded-lg"
                src={candidate.image || fallbackImageUrl}
                alt="Candidate"
                onError={(e) => {
                  e.target.src = fallbackImageUrl;
                }}
              />
            </div>

            <div className="gap-y-1 flex flex-col max-w-[420px]">
              <div className="flex items-center">
                <h5 className="text-xl font-bold tracking-tight text-gray-900">
                  {candidate.name}
                </h5>

                {candidate.linkedin && (
                  <img
                    src={linkedin}
                    alt={candidate.name}
                    title="Linkedin icon"
                    className="h-5 w-5 ml-2 rounded-sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      let url = candidate.linkedin;
                      if (!url.startsWith("https://")) {
                        url = "https://" + url;
                      }
                      window.open(url, "_blank");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                )}

                {candidate.cv && (
                  <img
                    src={cv}
                    alt={candidate.cv}
                    title="CV icon"
                    className="h-5 w-5 ml-2"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(candidate.cv, "_blank");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                )}
                {candidate.github && (
                  <img
                    src={github}
                    alt={candidate.github}
                    title="Github icon"
                    className="h-5 w-5 ml-2"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(candidate.github, "_blank");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                )}
                {candidate.video && (
                  <img
                    src={VideoIntro}
                    alt={candidate.video}
                    title="Video Intro icon"
                    className="h-6  w-6 ml-2"
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(candidate.video, "_blank");
                    }}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </div>
              <div className="">
                {candidate.roles &&
                  candidate.roles.map((role, index) => (
                    <span
                      key={index}
                      className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-sm font-medium ${bgColor} ${textColor}`}
                    >
                      {role}
                    </span>
                  ))}
                {candidate.sales && (
                  <span
                    className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
                  >
                    {candidate.sales}
                  </span>
                )}
                {candidate.swe_roles &&
                  candidate.swe_roles.map((role, index) => (
                    <span
                      key={index}
                      className={`inline-flex mr-2 mb-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
                    >
                      {role.trim()}
                    </span>
                  ))}
                {candidate.time_coding && (
                  <span
                    className={`inline-flex mr-2 items-center gap-x-1.5 rounded-md px-2 py-1 text-sm md:text-md font-medium ${bgColor} ${textColor}`}
                  >
                    {candidate.time_coding} of coding exp
                  </span>
                )}
              </div>
              <div className="flex">
                {candidate.working_style &&
                  Array.isArray(candidate.working_style) &&
                  workingStyleMapping.length > 0 && (
                    <p className="pr-4 font-normal flex text-sm text-black ">
                      <img className="h-6 pr-1 pb-1" src={Office} />
                      {workingStyleMapping.join(" or ")}
                    </p>
                  )}
                {candidate.languages && candidate.languages.length > 0 && (
                  <p className="font-normal flex text-sm text-black ">
                    <img className="h-5  pr-1" src={Languages} />
                    {candidate.languages
                      .filter((language) => language !== "N/A")
                      .join(", ")}
                  </p>
                )}
              </div>

              <div>
                <h5
                  className={`flex items-center text-sm tracking-tight text-gray-900`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-5 mr-2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z"
                    />
                  </svg>
                  <span>Expected Salary: </span>
                  {candidate.minimum_salary && (
                    <span className="text-sm font-semibold tracking-tight text-gray-900 ml-2">
                      {candidate.minimum_salary} - {candidate.ideal_salary}
                      {candidate.commission_expectation &&
                        ` + ${candidate.commission_expectation} commission`}
                    </span>
                  )}
                </h5>
              </div>
            </div>
          </div>

          <div className="pt-2 flex flex-col justify-between">
            <p className="text-sm">
              {candidate.undergrad_degree_type && (
                <p className="text-sm">
                  <span className="font-semibold">
                    {candidate.undergrad_degree_type}{" "}
                    {candidate.undergrad_degree_subject}
                  </span>
                  {candidate.undergrad_university && " @ "}
                  {candidate.undergrad_university} ({candidate.undergrad_result}
                  {candidate.undergrad_result &&
                    candidate.undergrad_graduation_year &&
                    " - "}
                  {candidate.undergrad_graduation_year})
                </p>
              )}

              {candidate.postgrad_degree_type && (
                <p className="text-sm pt-2">
                  <span className="font-semibold">
                    {candidate.postgrad_degree_type}{" "}
                    {candidate.postgrad_degree_subject}
                  </span>
                  {candidate.postgrad_university && " @ "}
                  {candidate.postgrad_university} ({candidate.postgrad_result}
                  {candidate.postgrad_result &&
                    candidate.postgrad_graduation_year &&
                    " - "}
                  {candidate.postgrad_graduation_year})
                </p>
              )}
            </p>

            <div className={`pb-2 mt-2 px-2 rounded-md ${workBgColor}`}>
              {candidate.recentTarget && (
                <div className="font-normal text-sm text-gray-700 pt-2">
                  <span className="font-semibold text-black">
                    Recent Target Achieved:
                  </span>{" "}
                  {candidate.recentTarget}
                </div>
              )}
            
{candidate.work_experience_1_company && (
          <>
            <p className="text-sm pt-2">
              <span className="font-semibold">
                {candidate.work_experience_1_role}
              </span>{" "}
              @ {candidate.work_experience_1_company}
              {candidate.work_experience_1_duration && 
                ` (${formatDuration(candidate.work_experience_1_duration)})`}
            </p>
            <p className="text-gray-500 text-sm">
              {candidate.work_experience_1_description}
            </p>
          </>
        )}

        {candidate.work_experience_2_company && (
          <>
            <p className="text-sm pt-2">
              <span className="font-semibold">
                {candidate.work_experience_2_role}
              </span>{" "}
              @ {candidate.work_experience_2_company}
              {candidate.work_experience_2_duration && 
                ` (${formatDuration(candidate.work_experience_2_duration)})`}
            </p>
            <p className="text-gray-500 text-sm">
              {candidate.work_experience_2_description}
            </p>
          </>
        )}
      </div>

            {candidate.description && (
              <p className="mt-2 font-normal text-sm text-gray-700 flex">
                <div className="mr-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-5"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16.5 18.75h-9m9 0a3 3 0 0 1 3 3h-15a3 3 0 0 1 3-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 0 1-.982-3.172M9.497 14.25a7.454 7.454 0 0 0 .981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 0 0 7.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 0 0 2.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 0 1 2.916.52 6.003 6.003 0 0 1-5.395 4.972m0 0a6.726 6.726 0 0 1-2.749 1.35m0 0a6.772 6.772 0 0 1-3.044 0"
                    />
                  </svg>
                </div>

                {candidate.description}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ShortlistCard;
