import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CandidateCard from "../../components/candidates/condensed_card.js";
import {
  RolesLoading,
  CandidateCardLoading,
} from "../../components/loading/loading_interviews.js";

export default function Declined() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const [loadingCandidates, setLoadingCandidates] = useState(true);
  const [error, setError] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState();
  const [filter, setFilter] = useState("");
  const [roles, setRoles] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [activeRole, setActiveRole] = useState("");
  const [interviewNeedUpdate, setInterviewNeedUpdate] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [showFinalPopup, setShowFinalPopup] = useState(false);
  const [candidatesRequest, setCandidatesRequest] = useState(0);
  const [isRequested, setIsRequested] = useState(true);

  const handleSelectCandidate = (candidate) => {
    setSelectedCandidate(candidate);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/signin");
    }
  }, [navigate]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/roles/fetchRoles", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const roles = response.data.roles;

        const transformedData = roles.map((role) => {
          return {
            id: role.id,
            title: role["Role Title"],
          };
        });

        setRoles(transformedData);
        setLoadingRoles(false);
      } catch (err) {
        setError(err.message);
        setLoadingRoles(false);
      }
    };

    const transformRoles = (roles) => {
      const roleSet = new Set(roles);
      if (roleSet.size === 1) {
        if (roleSet.has("Full Stack Developer")) return ["Full Stack"];
        if (roleSet.has("Front End Developer")) return ["Front End"];
        if (roleSet.has("Back End Developer")) return ["Back End"];
      }
      if (
        roleSet.has("Front End Developer") &&
        roleSet.has("Back End Developer")
      ) {
        return ["Full Stack"];
      }
      if (roleSet.has("Full Stack Developer")) {
        return ["Full Stack"];
      }
      return roles;
    };

    const fetchInterviews = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/interviews/fetchInterviews", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const interviews = response.data.interviews;
        console.log(interviews);

        const transformedCandidate = interviews.map((interview, index) => {
          try {
            const stream =
              interview.fields["Stream (from CRM) (from Candidate)"]?.[0];

            let sweRoles = interview.fields["SWE relevant roles"];
            if (sweRoles) {
              sweRoles = Array.isArray(sweRoles)
                ? sweRoles[0].split(",").map((role) => role.trim())
                : sweRoles.split(",").map((role) => role.trim());
              sweRoles = transformRoles(sweRoles);
            } else {
              sweRoles = [];
            }

            return {
              role: Array.isArray(interview.fields?.["Role"])
                ? interview.fields["Role"][0]
                : undefined,
              ranking: interview.fields?.["Ranking"] || undefined,
              role_title: Array.isArray(
                interview.fields?.["Role Title (from Role)"]
              )
                ? interview.fields["Role"][0]
                : undefined,
              interviewId: interview.fields?.["id"] || undefined,
              url_id: interview.fields?.["ID (from Candidate)"] || undefined,
              stage: interview.fields?.["Stage"] || undefined,
              linkedin: Array.isArray(
                interview.fields?.["LinkedIn RAW (from Candidate)"]
              )
                ? interview.fields["LinkedIn RAW (from Candidate)"][0]
                : undefined,
              cv: Array.isArray(interview.fields?.["CV RAW (from Candidate)"])
                ? interview.fields["CV RAW (from Candidate)"][0]
                : undefined,
              name: Array.isArray(interview.fields?.["Name (from Candidate)"])
                ? interview.fields["Name (from Candidate)"][0]
                : undefined,
              lastName: Array.isArray(
                interview.fields?.["Surname (from Candidate)"]
              )
                ? interview.fields["Surname (from Candidate)"][0]
                : undefined,
              video: Array.isArray(
                interview.fields?.["Raw video intro (from Candidate)"]
              )
                ? interview.fields["Raw video intro (from Candidate)"][0]
                : undefined,
              undergrad_degree_type:
                interview.fields?.["Undergrad degree type (from Candidate)"] ||
                "",
              undergrad_degree_subject:
                interview.fields?.["Undergrad subject (from Candidate)"] || "",
              undergrad_university:
                interview.fields?.["Undergrad university (from Candidate)"] ||
                "",
              undergrad_graduation_year:
                interview.fields?.[
                  "Undergrad graduation year (from Candidate)"
                ] || "",
              undergrad_result:
                interview.fields?.["Undergrad result (from Candidate)"] || "",
              postgrad_degree_type:
                interview.fields?.["Postgrad degree type (from Candidate)"] ||
                "",
              postgrad_degree_subject:
                interview.fields?.["Postgrad subject (from Candidate)"] || "",
              postgrad_university:
                interview.fields?.["Postgrad university (from Candidate)"] ||
                "",
              postgrad_graduation_year:
                interview.fields?.[
                  "Postgrad graduation year (from Candidate)"
                ] || "",
              postgrad_result:
                interview.fields?.["Postgrad result (from Candidate)"] || "",
              roles:
                interview.fields?.[
                  "Generalist roles suitable for copy (from Candidate)"
                ] || undefined,
              email: Array.isArray(interview.fields?.["Email (from Candidate)"])
                ? interview.fields["Email (from Candidate)"][0]
                : undefined,
              working_style:
                interview.fields?.[
                  "Preferred working style copy (from Candidate)"
                ] || undefined,
              image:
                interview.fields?.["Photo (from Candidate)"]?.[0]?.url ||
                undefined,
              work_experience_1_role:
                interview.fields?.[
                  "(1) Work Experience Role (from Candidate)"
                ] || "",
              work_experience_1_company:
                interview.fields?.[
                  "(1) Work Experience Company (from Candidate)"
                ] || "",
              work_experience_1_description:
                interview.fields?.[
                  "(1) Work Experience Description (from Candidate)"
                ] || "",
              work_experience_1_duration:
                interview.fields?.[
                  "(1) Time spent in work experience (from Candidate)"
                ] || "",
              work_experience_2_role:
                interview.fields?.[
                  "(2) Work Experience Role (from Candidate)"
                ] || "",
              work_experience_2_company:
                interview.fields?.[
                  "(2) Work Experience Company (from Candidate)"
                ] || "",
              work_experience_2_description:
                interview.fields?.[
                  "(2) Work Experience Description (from Candidate)"
                ] || "",
              work_experience_2_duration:
                interview.fields?.[
                  "(2) Time spent in work experience (from Candidate)"
                ] || "",
              languages:
                interview.fields?.["Languages copy (from Candidate)"] ||
                undefined,
              description:
                interview.fields?.["Top achievement (from Candidate)"] ||
                undefined,
              mostRecentCohort: Array.isArray(
                interview.fields?.["Most recent cohort (from Candidate)"]
              )
                ? interview.fields["Most recent cohort (from Candidate)"][0]
                : undefined,
              recentTarget:
                interview.fields?.["Recent target achieved (from Candidate)"] ||
                undefined,
              stream: stream || undefined, // Add the stream field
              rationale:
                interview.fields?.["Application Rationale"] || undefined,
              sales:
                interview.fields?.[
                  "Proven Sales Experience / High Potential for Sales (from Candidate)"
                ] || undefined,
              github: Array.isArray(
                interview.fields?.["Github RAW (from Candidate)"]
              )
                ? interview.fields["Github RAW (from Candidate)"][0]
                : "",
              exp_swe:
                interview.fields?.["Level of exp SWE (from Candidate)"] || "",
              portfolio: Array.isArray(
                interview.fields?.["Raw Portfolio (from Candidate)"]
              )
                ? interview.fields["Raw Portfolio (from Candidate)"][0]
                : "",
              time_coding:
                interview.fields?.["Time spent coding (from Candidate)"] || "",
              coding_languages: Array.isArray(
                interview.fields?.["Coding languages new (from Candidate)"]
              )
                ? interview.fields["Coding languages new (from Candidate)"] ||
                  ""
                : "",

              swe_roles: sweRoles,
              minimum_salary:
                interview.fields?.["Minimum salary (from Candidate)"] || "",
              ideal_salary:
                interview.fields?.["Ideal salary (from Candidate)"] || "",
            };
          } catch (error) {
            console.error(`Error processing interview at index ${index}`, error);
          }
        });

        console.log(transformedCandidate);

        const filteredCandidates = transformedCandidate.filter(
          (candidate) =>
            candidate.stage !== "Interviewing" &&
            candidate.stage !== "Application pending" &&
            candidate.stage !== "Offer accepted" &&
            candidate.stage !== "Offer made" &&
            candidate.stage !== "Match requested"
        );

        setCandidates(filteredCandidates);

        const requestCount = filteredCandidates.filter(
          (candidate) => candidate.stage === "Application pending"
        ).length;
        setCandidatesRequest(requestCount);

        setLoadingCandidates(false);
      } catch (err) {
        if (
          err.response &&
          err.response.data &&
          err.response.data.message === "Internal Server Error" &&
          err.response.data.error ===
            "Error: No interviews found for this company"
        ) {
          console.log("Yes");
          setCandidates([]); // Set empty state
          setLoadingCandidates(false);
        } else {
          setError(err.message);
          setLoadingCandidates(false);
        }
      }
    };

    if (!interviewNeedUpdate) return;

    fetchRoles();
    fetchInterviews();
    setInterviewNeedUpdate(false);
  }, [interviewNeedUpdate]);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const filteredData = candidates.filter((candidate) => {
    if (filter && candidate.stage !== filter) return false;
    if (activeRole && candidate.role !== activeRole) return false;
    return true;
  });

  return (
    <>
      <div className="relative bg-gray-100 min-h-screen">
        <div>
          <div className="flex lg:ml-[200px] h-full pb-20 justify-center items-center bg-gray-100">
            <div className="max-w-[1500px] w-full">
              <div className="bg-white pl-12 pt-6 pb-4 fixed top-18 z-20 w-full border-b">
                <div className="flex justify-left items-center">
                  <div>
                    <h2 className="text-3xl">Declined</h2>
                    <div className="mt-2">
                      You have{" "}
                      <span className="font-bold">{filteredData.length} </span>{" "}
                      declined candidates.
                    </div>
                  </div>
                </div>
              </div>
              {loadingCandidates ? (
                <div className="grid pt-28 ml-12 grid-cols-1 mr-5 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {Array(6)
                    .fill(0)
                    .map((_, index) => (
                      <CandidateCardLoading key={index} />
                    ))}
                </div>
              ) : candidates.length === 0 ? (
                <div className="flex justify-center">
                  <div className="mt-60 bg-white max-w-md rounded-md p-4 text-center">
                    <h2 className="text-xl">
                      You don't have any declined candidates yet!
                    </h2>
                    <button
                      onClick={() => {
                        setShowFinalPopup(false);
                        navigate("/browse-candidates");
                      }}
                      className="px-4 mt-4 text-md py-2 bg-[#02B491] text-white rounded-md hover:bg-white border-2 border-[#02B491] hover:text-black"
                    >
                      Browse Candidates
                    </button>
                    <button
                      onClick={() => {
                        setShowFinalPopup(false);
                        navigate("/in-process");
                      }}
                      className="ml-2 px-4 mt-4 text-md py-2 hover:bg-[#02B491] hover:text-white rounded-md bg-white border-2 border-[#02B491] hover:text-black"
                    >
                      View in process
                    </button>
                  </div>
                </div>
              ) : (
                <div className="grid pt-28 ml-12 grid-cols-1 mr-5 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {filteredData.map((candidate, index) => (
                    <CandidateCard
                      key={index}
                      candidate={candidate}
                      onSelectCandidate={handleSelectCandidate}
                      setInterviewNeedUpdate={setInterviewNeedUpdate}
                      setShowPopup={setShowPopup}
                      setShowFinalPopup={setShowFinalPopup}
                      candidatesRequest={candidatesRequest}
                      setCandidatesRequest={setCandidatesRequest}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
