import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import CandidateCard from "../../components/candidates/condensed_card.js";
import {
  CandidateCardLoading,
} from "../../components/loading/loading_interviews.js";

export default function Requests() {
  const navigate = useNavigate();
  const [candidates, setCandidates] = useState([]);
  const [loadingCandidates, setLoadingCandidates] = useState(true);
  const [error, setError] = useState(null);
  const [interviewNeedUpdate, setInterviewNeedUpdate] = useState(true);

  // Optional for extra filtering
  const [filter, setFilter] = useState("");
  const [activeRole, setActiveRole] = useState("");

  // For selecting or counting
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [candidatesRequest, setCandidatesRequest] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [showFinalPopup, setShowFinalPopup] = useState(false);

  const handleSelectCandidate = (candidate) => {
    setSelectedCandidate(candidate);
  };

  // Make sure the user is logged in
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/signin");
    }
  }, [navigate]);

  // Fetch from your Interviews table, *only* those in "Match requested" stage
  useEffect(() => {
    if (!interviewNeedUpdate) return;

    const fetchMatchRequested = async () => {
      try {
        const token = localStorage.getItem("token");
        const stage = "Match requested"; // <–– REPLACE with your exact stage name in Airtable

        // Pass the desired stage to the server so it filters on the backend:
        const response = await axios.get("/api/interviews/fetchInterviews", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { stage: encodeURIComponent(stage) },
        });

        const interviews = response.data.interviews || [];

        // Transform interviews for <CandidateCard>:
        const transformed = interviews.map((iv) => ({
          interviewId: iv.fields["id"] || "",
          url_id: iv.fields["ID (from Candidate)"] || "",
          stage: iv.fields["Stage"] || "",
          linkedin:
            iv.fields["LinkedIn raw URL (from Candidate)"]?.[0] || undefined,
          cv: iv.fields["Raw CV (from Candidate)"]?.[0] || undefined,
          name: iv.fields["Name (from Candidate)"]?.[0] || "",
          lastName: iv.fields["Surname (from Candidate)"]?.[0] || "",
          video: iv.fields["Raw video intro (from Candidate)"] || undefined,
          education:
            iv.fields["Degree details (from Candidate)"]?.[0] || undefined,
          roles:
            iv.fields["Generalist roles suitable for copy (from Candidate)"] ||
            [],
          minimum_salary:
            iv.fields["Minimum salary (from Candidate)"] || "",
          ideal_salary: iv.fields["Ideal salary (from Candidate)"] || "",
          email: iv.fields["Email (from Candidate)"]?.[0] || "",
          working_style:
            iv.fields["Preferred working style copy (from Candidate)"] || "",
          image: iv.fields["Photo (from Candidate)"]?.[0]?.url || undefined,
          work_experience_1_role:
            iv.fields["(1) Work Experience Role (from Candidate)"] || "",
          work_experience_1_company:
            iv.fields["(1) Work Experience Company (from Candidate)"] || "",
          work_experience_1_description:
            iv.fields["(1) Work Experience Description (from Candidate)"] || "",
            work_experience_1_duration: 
            iv.fields["(1) Time spent in work experience (from Candidate)"]?.[0] || "",
          work_experience_2_role:
            iv.fields["(2) Work Experience Role (from Candidate)"] || "",
          work_experience_2_company:
            iv.fields["(2) Work Experience Company (from Candidate)"] || "",
          work_experience_2_description:
            iv.fields["(2) Work Experience Description (from Candidate)"] || "",
            work_experience_2_duration:
            iv.fields["(2) Time spent in work experience (from Candidate)"]?.[0] || "",
          languages: iv.fields["Languages copy (from Candidate)"] || [],
          description: iv.fields["Top achievement (from Candidate)"] || "",
          mostRecentCohort:
            iv.fields["Most recent cohort (from Candidate)"]?.[0] || "",
          recentTarget:
            iv.fields["Recent target achieved (from Candidate)"] || "",
          stream:
            iv.fields["Stream (from CRM) (from Candidate)"]?.[0] || "",
          rationale: iv.fields["Application Rationale"] || "",
          sales:
            iv.fields[
              "Proven Sales Experience / High Potential for Sales (from Candidate)"
            ] || "",
        }));

        setCandidates(transformed);

        // Example: how many are “Application pending” (if you want):
        const requestCount = transformed.filter(
          (cand) => cand.stage === "Application pending"
        ).length;
        setCandidatesRequest(requestCount);

        setLoadingCandidates(false);
      } catch (err) {
        console.error("Error fetching 'Match requested' interviews:", err);
        setError(err.message);
        setLoadingCandidates(false);
      }
    };

    fetchMatchRequested();
    setInterviewNeedUpdate(false);
  }, [interviewNeedUpdate]);

  // If you have local filtering on top of that:
  const filteredData = candidates.filter((candidate) => {
    if (filter && candidate.stage !== filter) return false;
    if (activeRole && candidate.role !== activeRole) return false;
    return true;
  });

  return (
    <div className="relative bg-gray-100 min-h-screen">
      <div className="flex lg:ml-[220px] h-full pb-20 justify-center items-center bg-gray-100">
        <div className="max-w-[1500px] w-full">
          {/* Header */}
          <div className="bg-white pl-8 pt-6 pb-4 fixed top-18 z-20 w-full border-b">
            <div className="flex justify-left items-center">
              <div>
                <h2 className="text-3xl">Match Requests</h2>
                <div className="mt-2">
                  You are still waiting for{" "}
                  <span className="font-bold">{filteredData.length}</span>{" "}
                  candidate(s) to respond to your match requests.
                </div>
              </div>
            </div>
          </div>

          {/* Body */}
          {loadingCandidates ? (
            <div className="grid grid-cols-1 mr-5 ml-8 mt-28 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {Array(6)
                .fill(0)
                .map((_, index) => (
                  <CandidateCardLoading key={index} />
                ))}
            </div>
          ) : candidates.length === 0 ? (
            <div className="flex justify-center">
              <div className="mt-60 bg-white max-w-md rounded-md p-4 text-center">
                <h2 className="text-xl">
                  You don&apos;t have any match requests yet!
                </h2>
                <button
                  onClick={() => {
                    setShowFinalPopup(false);
                    navigate("/browse-candidates");
                  }}
                  className="px-4 mt-4 text-md py-2 bg-[#02B491] text-white rounded-md hover:bg-white border-2 border-[#02B491] hover:text-black"
                >
                  Browse Candidates
                </button>
                <button
                  onClick={() => {
                    setShowFinalPopup(false);
                    navigate("/in-process");
                  }}
                  className="ml-2 px-4 mt-4 text-md py-2 hover:bg-[#02B491] hover:text-white rounded-md bg-white border-2 border-[#02B491] hover:text-black"
                >
                  View in process
                </button>
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-1 mr-5 ml-8 mt-28 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {filteredData.map((candidate, index) => (
                <CandidateCard
                  key={index}
                  candidate={candidate}
                  onSelectCandidate={handleSelectCandidate}
                  setInterviewNeedUpdate={setInterviewNeedUpdate}
                  setShowPopup={setShowPopup}
                  setShowFinalPopup={setShowFinalPopup}
                  candidatesRequest={candidatesRequest}
                  setCandidatesRequest={setCandidatesRequest}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
