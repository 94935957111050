import { useParams, useLocation, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import VideoRecording from "../../components/roles/video_recording";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function NewRole() {
  const query = useQuery();
  const roleId = query.get("roleId");
  const navigate = useNavigate();

  const [animationClass, setAnimationClass] = useState("animate-fadeInUp");
  const [spotlights, setSpotlights] = useState([]);
  const [selectedSpotlight, setSelectedSpotlight] = useState(null);
  const [uploadChoice, setUploadChoice] = useState(""); // Track user's choice (File or Link)
  const [file, setFile] = useState(null); // For holding the selected file
  const [isUploading, setIsUploading] = useState(false); // For handling the upload state
  const [message, setMessage] = useState(""); // For displaying success/error messages
  const [spotlightNeedUpdate, setSpotlightNeedUpdate] = useState(true);

  const [step, setStep] = useState(1);
  const [percentageCompleted, setPercentageCompleted] = useState(0);
  const totalSteps = 13;
  let intervalId = null; // Variable to track the interval ID

  // State to store selected answers for questions 4, 5, and 6
  const [selectedAnswers, setSelectedAnswers] = useState({
    q4: "", // Will store "yes" or "no" for equity
    q5: "", // Will store selected stream (generalist, sales, marketing)
  });

  const [role, setRole] = useState({
    title: "",
    compensation: "",
    working_style: "",
    hiring_lead_email: "",
    hiring_lead_linkedin: "",
    hiring_lead_name: "",
    interview_process: "",
    job_description_link: "",
    key_experience: "",
    equity: "",
    stream: "",
    open_to_applicants: "",
    spotlight_context: "",
    spotlight: "",
  });

  const [prevRole, setPrevRole] = useState(role); // Store previous role state to detect changes

  // Function to check if any role field has changed
  const hasRoleChanged = () => {
    return Object.keys(role).some((key) => role[key] !== prevRole[key]);
  };

  const handleUpload = async (file) => {
    if (!file) {
      console.log("no");
      setMessage("Please select a file to upload.");

      return;
    }

    console.log("yes");

    // Start the loading animation
    setIsUploading(true);

    const CLOUDINARY_CLOUD_NAME = "dkjsnpplv"; // You can replace this with your variable
    const CLOUDINARY_UPLOAD_PRESET = "ml_default"; // You can replace this with your variable
    const CLOUDINARY_API_KEY = "815772339983468"; // You can replace this with your variable
    const CLOUDINARY_FOLDER = "JobDescription"; // You can replace this with your variable

    const url = `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/raw/upload`;

    try {
      // Get the Cloudinary signature and timestamp
      const {
        data: { signature, timestamp },
      } = await axios.get("/api/cloudinarySignature", {
        params: { upload_preset: CLOUDINARY_UPLOAD_PRESET },
      });

      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
      formData.append("timestamp", timestamp);
      formData.append("api_key", CLOUDINARY_API_KEY);
      formData.append("signature", signature);
      formData.append("folder", CLOUDINARY_FOLDER);

      // Make the upload request to Cloudinary
      const response = await axios.post(url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      // Update the state and form data with the uploaded file URL
      setMessage("File uploaded successfully!");
      setRole((prevFormData) => ({
        ...prevFormData,
        job_description_link: response.data.secure_url,
      }));
    } catch (error) {
      // Handle the error
      setMessage(
        `Error: ${error.response ? error.response.data.message : error.message}`
      );
    } finally {
      // End the loading animation
      setIsUploading(false);
    }
  };

  // Function to send role data to the backend
  const sendRoleUpdate = async () => {
    if (hasRoleChanged()) {
      // Map the role data to match the field names used in the backend
      const updatedData = {
        fields: {
          "Working Style": role.working_style,
          "Hiring Lead Email Address": role.hiring_lead_email,
          "Hiring Lead Linkedin": role.hiring_lead_linkedin,
          "Interview Process": role.interview_process,
          "Hiring Lead Name": role.hiring_lead_name,
          Compensation: role.compensation,
          "Role Title": role.title,
          "Specific Experience": role.key_experience,
          "Wants to receive applicants": role.open_to_applicants,
        },
        roleId: roleId, // Include the role ID
      };

      // Add "Spotlight Context" to updatedData if it exists in the role
      if (role.spotlight_context) {
        updatedData.fields["Spotlight Context"] = role.spotlight_context;
      }

      if (role.equity) {
        updatedData.fields["Equity"] = role.equity;
      }

      // Add "Job Description PDF" if it exists (assuming it's stored in role.job_description_link)
      if (role.job_description_link) {
        updatedData.fields["Job Description PDF"] = role.job_description_link;
      }

      console.log(role.stream);

      if (selectedAnswers["q5"]) {
        updatedData.fields["Stream"] = selectedAnswers["q5"];
      }

      // Update the spotlight if selected
      if (selectedSpotlight) {
        updatedData.fields.Spotlight = [selectedSpotlight.id]; // Spotlight is an array with spotlight ID
      } else {
        updatedData.fields.Spotlight = []; // Empty array if no spotlight is selected
      }

      const token = localStorage.getItem("token");
      try {
        await axios.patch("/api/roles/updateRoleData", updatedData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        setPrevRole(role); // Update previous role state after successful patch
        console.log("Role updated successfully");
      } catch (error) {
        console.error("Error updating role:", error);
      }
    }
  };

  // useEffect to trigger on step change or role field change
  useEffect(() => {
    sendRoleUpdate();
  }, [step]);

  // Example function to move to the next step and update role field

  const updateRoleField = (field, value) => {
    setRole((prev) => ({ ...prev, [field]: value }));
  };

  useEffect(() => {
    const fetchRole = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get("/api/roles/fetchRoleById", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            id: roleId, // Pass the roleId in the request
          },
        });

        const role = response.data.role;
        console.log(role);

        // Transform role data
        const transformedData = {
          id: role["id"] || "",
          working_style: role.fields["Working Style"] ?? "",
          hiring_lead_email: role.fields["Hiring Lead Email Address"] ?? "",
          hiring_lead_linkedin: role.fields["Hiring Lead Linkedin"] ?? "",
          interview_process: role.fields["Interview Process"] ?? "",
          hiring_lead_name: role.fields["Hiring Lead Name"] ?? "",
          compensation: role.fields["Compensation"] ?? "",
          title: role.fields["Role Title"] ?? "",
          key_experience: role.fields["Specific Experience"] ?? "",
          equity: role.fields["Equity"] ?? "",
          stream: role.fields["Stream"] ?? "",
          open_to_applicants: role.fields["Wants to receive applicants"] ?? "",
          spotlight_context: role.fields["Spotlight Context"] ?? "",
          spotlight: role.fields["Spotlight"],
          name: role.fields["Primary Field"],
        };

        setRole(transformedData);

        setSelectedAnswers({
          q4: transformedData.equity,
          q5: transformedData.stream ?? [],
        });

        // Fetch spotlights
        const spotlightsResponse = await axios.get(
          "/api/spotlights/fetchSpotlights",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const spotlights = spotlightsResponse.data.spotlights;
        const transformedSpotlights = spotlights.map((spotlight) => ({
          id: spotlight["id"],
          dropbox_url: spotlight["URL Dropbox cleaned"] || null,
          loom_url: spotlight["URL Loom cleaned"] || null,
          name: spotlight["Name"],
        }));

        setSpotlights(transformedSpotlights);

        // Set selected spotlight logic
        let spotlightToSelect = null;

        if (transformedData.spotlight) {
          spotlightToSelect = transformedSpotlights.find(
            (s) => s.id === transformedData.spotlight[0]
          );
        }

        // If no specific spotlight is found, select the most recent spotlight
        if (!spotlightToSelect && transformedSpotlights.length > 0) {
          spotlightToSelect =
            transformedSpotlights[transformedSpotlights.length - 1];
        }

        setSelectedSpotlight(spotlightToSelect || null);
        role.spotlight = spotlightToSelect || null;
      } catch (err) {
        console.error("Error fetching role:", err);
      }
    };

    fetchRole();
  }, []);

  useEffect(() => {
    const fetchSpotlights = async () => {
      try {
        const token = localStorage.getItem("token");

        // Fetch spotlights
        const spotlightsResponse = await axios.get(
          "/api/spotlights/fetchSpotlights",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        const spotlights = spotlightsResponse.data.spotlights;
        const transformedSpotlights = spotlights.map((spotlight) => ({
          id: spotlight["id"],
          dropbox_url: spotlight["URL Dropbox cleaned"] || null,
          loom_url: spotlight["URL Loom cleaned"] || null,
          name: spotlight["Name"],
        }));

        setSpotlights(transformedSpotlights);
        setSpotlightNeedUpdate(false);
      } catch (err) {
        setSpotlights([]);
      }
    };
    if (spotlightNeedUpdate) fetchSpotlights();
  }, [spotlightNeedUpdate]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/signin");
    }
  }, []);

  const nextStep = () => {
    if (validateStep() && step !== 14) {
      setAnimationClass("animate-fadeOutDown");
      setTimeout(() => {
        setStep((prev) => prev + 1);
        setAnimationClass("animate-fadeInUp");
      }, 500); // Match the animation duration
    }
  };

  const previousStep = () => {
    if (step != 1) {
      setAnimationClass("animate-fadeOutDown");
      setTimeout(() => {
        setStep(step - 1);
        setAnimationClass("animate-fadeInUp");
      }, 500); // Match the animation duration
    }
  };

  const animatePercentage = (start, end) => {
    if (end < 0 || end > 100) {
      return; // Exit if the percentage is out of bounds
    }

    const duration = 500; // Animation duration in milliseconds
    const steps = Math.abs(end - start); // Total steps required
    if (steps === 0) return; // No need to animate if start and end are the same

    const stepTime = Math.floor(duration / steps); // Calculate time per step
    let current = start;
    const increment = end > start ? 1 : -1;

    clearInterval(intervalId); // Clear any previous intervals

    intervalId = setInterval(() => {
      current += increment;
      setPercentageCompleted(current);

      // Stop the interval when the target percentage is reached
      if (current === end || current < 0 || current > 100) {
        clearInterval(intervalId);
      }
    }, stepTime);
  };

  useEffect(() => {
    const newPercentage = Math.round(((step - 1) / (totalSteps - 1)) * 100);
    const boundedPercentage = Math.max(0, Math.min(newPercentage, 100)); // Ensure percentage is between 0 and 100
    animatePercentage(percentageCompleted, boundedPercentage); // Animate to the new percentage
  }, [step]); // Update when the step changes

  useEffect(() => {
    const handleKeyDown = (event) => {
      const isControlOrCommand = event.ctrlKey || event.metaKey; // Check if Ctrl or Command key is pressed

      if (isControlOrCommand && event.key.toLowerCase() === "ArrowRight") {
        nextStep(); // Move to next step if Ctrl/Cmd + N is pressed
      } else if (
        isControlOrCommand &&
        event.key.toLowerCase() === "ArrowLeft"
      ) {
        previousStep(); // Move to previous step if Ctrl/Cmd + P is pressed
      }
    };

    // Add event listener for keydown events
    window.addEventListener("keydown", handleKeyDown);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [step]);

  // Function to handle answer selection for questions 4, 5, and 6
  const handleSelectAnswer = (question, answer) => {
    setSelectedAnswers((prevAnswers) => {
      let updatedAnswers = { ...prevAnswers };

      if (question === "q4") {
        updateRoleField("equity", answer === "Yes" ? "Yes" : "No");
      }
      if (question === "q5") {
        updateRoleField("stream", answer);
      }

      if (question === "q5") {
        // Toggle selection for multiple options in question 5
        const currentSelection = prevAnswers.q5 || [];
        if (currentSelection.includes(answer)) {
          updatedAnswers.q5 = currentSelection.filter(
            (item) => item !== answer
          );
        } else {
          updatedAnswers.q5 = [...currentSelection, answer];
        }
      } else {
        // Toggle selection for single choice questions (q4 and q5)
        const wasPreviouslySelected = prevAnswers[question] === answer;
        updatedAnswers[question] = wasPreviouslySelected ? "" : answer;

        // Only go to the next step if the answer was not previously selected (i.e., if it was just selected)
        if (!wasPreviouslySelected && question === "q4") {
          setTimeout(() => {
            nextStep(); // Move to the next step after selection
          }, 300); // Add a slight delay to allow UI feedback before moving
        }
      }

      console.log(selectedAnswers);

      return updatedAnswers;
    });
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      const key = event.key.toLowerCase(); // Normalize the key for lowercase

      // Question 4 (Y for Yes, N for No)
      if (step === 4) {
        if (key === "y") {
          handleSelectAnswer("q4", "Yes");
        } else if (key === "n") {
          handleSelectAnswer("q4", "No");
        }
      }

      // Question 6 (G for Generalist, S for Sales, M for Marketing)
      if (step === 5) {
        if (key === "enter") {
          nextStep(); // Move to next step when "Enter" is pressed on step 6
        } else if (key === "g") {
          handleSelectAnswer("q5", "Generalist");
        } else if (key === "s") {
          handleSelectAnswer("q5", "Sales");
        } else if (key === "m") {
          handleSelectAnswer("q5", "Marketing");
        }
      }

      // Question 10 (Handle "Enter" key to move to the next step)
      if (step === 9) {
        if (key === "enter") {
          nextStep(); // Move to next step when "Enter" is pressed on step 10
        }
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [step, selectedAnswers]); // Ensure selectedAnswers updates as well

  const isFieldEmpty = () => {
    switch (step) {
      case 8:
        return !role?.hiring_lead_email; // Email field
      case 9:
        return !role?.hiring_lead_linkedin; // Linkedin field
      case 10:
        return !role?.job_description_link; // Linkedin field
      default:
        return true; // Default case for other steps
    }
  };

  const validateStep = () => {
    switch (step) {
      case 1:
        return role?.title?.trim() !== "";
      case 2:
        return role?.compensation?.trim() !== "";
      case 3:
        return role?.working_style?.trim() !== "";
      case 4:
        return selectedAnswers?.q4 !== ""; // Equity question

      case 5:
        return selectedAnswers?.q5?.length > 0; // Stream selection
      case 6:
        return role?.hiring_lead_name?.trim() !== "";
      case 7:
        return role?.hiring_lead_email?.trim() !== "";

      case 12:
        return (
          selectedSpotlight !== null || role?.spotlight_context?.trim() !== ""
        ); // Video recording or context
      default:
        return true; // For non-required fields or optional steps
    }
  };

  const [loading, setLoading] = useState();
  const [loadingEndFlow, setLoadingEndFlow] = useState();

  const handleSaveRole = async (publishToCandidates) => {
    // Set loading to true to show the animation
    setLoadingEndFlow(true);

    // Call the function to update the role data
    await sendRoleUpdate();

    const token = localStorage.getItem("token");
    try {
      await axios.patch(
        "/api/roles/updateRoleData",
        {
          fields: {
            "First saved": true,
            "Open to applications": publishToCandidates
              ? "Open to apps"
              : "Not open to apps",
          },
          roleId: roleId, // Include the role ID
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Error updating role:", error);
    }

    // Once the update is done, navigate to the /browse-candidates page
    navigate(`/role-created?roleId=${roleId}`);
  };

  if (loadingEndFlow) {
    return (
      <div className="flex flex-col justify-center items-center h-screen bg-white">
        <h2 className={`text-xl pb-2 font-bold ${animationClass}`}>
          Creating your role
        </h2>
        <div className="w-[200px] mb-40 h-1 rounded-md bg-gray-300 overflow-hidden relative">
          <div className="h-full w-1/2 rounded-md bg-black animate-slide-bar"></div>
        </div>
      </div>
    );
  }

  const handleSaveDraft = async () => {
    // Set loading to true to show the animation
    setLoading(true);

    // Call the function to update the role data
    await sendRoleUpdate();

    const token = localStorage.getItem("token");
    try {
      await axios.patch(
        "/api/roles/updateRoleData",
        {
          fields: {
            "First saved": true,
          },
          roleId: roleId, // Include the role ID
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Error updating role:", error);
    }

    // Once the update is done, navigate to the /browse-candidates page
    navigate("/browse-candidates");
  };

  if (loading) {
    return (
      <div className="flex flex-col justify-center items-center h-screen bg-white">
        <h2 className={`text-xl pb-2 font-bold ${animationClass}`}>
          Saving Draft
        </h2>
        <div className="w-[200px] mb-40 h-1 rounded-md bg-gray-300 overflow-hidden relative">
          <div className="h-full w-1/2 rounded-md bg-black animate-slide-bar"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col overflow-y-hidden items-center bg-white">
      <div className="fixed z-50 top-6 left-6 flex items-center mb-6">
        <div className="flex items-center mb-6 ">
          <img
            src="https://www.jumpstart-uk.com/wp-content/uploads/2022/05/jumpstart-logo-black.svg"
            className="h-6 my-2 px-4 sm:block hidden"
            alt="Jumpstart Logo"
          />
        </div>
      </div>

      <h className="text-lg fixed top-10 right-60">
        {percentageCompleted}% completed
      </h>

      <button
        onClick={handleSaveDraft} // Call the save draft function}
        className={`fixed top-8 right-20 py-2 px-4 max-w-[200px] text-md flex justify-center border-2 rounded-md ${
          step === 1 && !validateStep()
            ? "bg-gray-300 text-gray-400 border-gray-300 cursor-not-allowed" // Disabled state
            : "bg-[#02B491] text-white border-[#02B491] hover:bg-white hover:text-black" // Enabled state
        }`}
        disabled={step === 1 && !validateStep()} // Disable only on step 1 if not valid
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          className="size-6 mr-2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
          />
        </svg>
        Save draft
      </button>

      <div className="fixed pb-10 pt-4 bg-white z-50 bottom-0 w-full flex flex-col space-y-4">
        {/* Progress bar section */}
        <div className="w-full flex justify-between items-center gap-0 space-x-0">
          <div className="w-full h-2 bg-gray-300">
            {/* Progress fill */}
            <div
              className="h-2 bg-[#02B491] transition-all duration-500 ease-out"
              style={{ width: `${((step - 1) / 13) * 100}%` }} // Calculate progress based on completed steps
            ></div>
          </div>
        </div>

        {/* Next and Previous text section */}
        <div className="w-full flex justify-between items-center pt-2">
          {/* Previous text on the far left */}
          {step !== 1 ? (
            <div
              onClick={() => previousStep()}
              className="text-left flex px-4 ml-10 cursor-pointer rounded-md hover:bg-black/10 py-2"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-6 mr-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
                />
              </svg>

              <p className="text-gray-700 font-medium ">Previous</p>
            </div>
          ) : (
            <div></div>
          )}

          {step !== 12 ? (
            <div
              onClick={() => nextStep()}
              disabled={!validateStep()}
              className={`text-right px-4 mr-10 flex cursor-pointer py-2 rounded-md ${
                validateStep()
                  ? "hover:bg-black/10"
                  : "opacity-50 cursor-not-allowed"
              }`}
            >
              <p className="text-gray-700 font-medium ">Next</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-6 ml-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                />
              </svg>
            </div>
          ) : (
            <div
              onClick={() => handleSaveRole()}
              className="text-right px-4 mr-10 flex cursor-pointer hover:bg-black/10 py-2 rounded-md"
            >
              <p className="text-gray-700 font-medium ">Create role</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-6 ml-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
                />
              </svg>
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-left pl-[25%] items-center h-screen w-full bg-white">
        <div className="flex flex-col items-left  mb-40 max-w-[900px]">
          {step === 1 && (
            <div className={`flex flex-col items-left ${animationClass}`}>
              <h2 className="text-4xl pb-8 font-semibold">
                1. What is the role title?<span className="text-black">*</span>
              </h2>
              <input
                className="border-b-2 ml-8 font-normal w-[620px] border-gray-200 text-3xl placeholder:text-gray-400 focus:border-black outline-none transition-colors duration-200"
                placeholder="Founder's Associate"
                value={role?.title || ""}
                onChange={(e) => updateRoleField("title", e.target.value)}
                autoFocus
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    nextStep(); // Triggers the next step when 'Enter' is pressed
                  }
                }}
              />
              <div className="flex items-center ">
                <button
                  onClick={() => validateStep() && nextStep()} // Only go to the next step if the validation passes
                  className={`mt-4 ml-8 text-xl w-[70px] font-bold py-2 px-4 bg-white border-2 rounded-md ${
                    validateStep()
                      ? "border-[#02B491] hover:bg-[#02B491] hover:text-white"
                      : "border-gray-300 text-gray-400 cursor-not-allowed"
                  }`}
                  disabled={!validateStep()} // Disable the button if the step is invalid
                >
                  OK
                </button>

                <h className="text-sm ml-4 mt-4">
                  press <span className="font-bold">Enter</span>
                </h>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-5 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m7.49 12-3.75 3.75m0 0 3.75 3.75m-3.75-3.75h16.5V4.499"
                  />
                </svg>
              </div>
            </div>
          )}
          {step === 2 && (
            <div className={`flex flex-col items-left ${animationClass}`}>
              <h2 className="text-4xl pb-8 font-semibold">
                2. What is the compensation for the role?
                <span className="text-black">*</span>
              </h2>
              <input
                className="border-b-2 ml-10 font-normal w-[620px] border-gray-200 text-3xl placeholder:text-gray-400 focus:border-black outline-none transition-colors duration-200"
                placeholder="£40-50,000 + £10k commission"
                autoFocus
                value={role?.compensation || ""}
                onChange={(e) =>
                  setRole({ ...role, compensation: e.target.value })
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    nextStep(); // Triggers the next step when 'Enter' is pressed
                  }
                }}
              />
              <div className="flex items-center ">
                <button
                  onClick={() => validateStep() && nextStep()} // Only go to the next step if the validation passes
                  className={`mt-4 ml-10 text-xl w-[70px] font-bold py-2 px-4 bg-white border-2 rounded-md ${
                    validateStep()
                      ? "border-[#02B491] hover:bg-[#02B491] hover:text-white"
                      : "border-gray-300 text-gray-400 cursor-not-allowed"
                  }`}
                  disabled={!validateStep()} // Disable the button if the step is invalid
                >
                  OK
                </button>
                <h className="text-sm ml-4 mt-4">
                  press <span className="font-bold">Enter</span>
                </h>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-5 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m7.49 12-3.75 3.75m0 0 3.75 3.75m-3.75-3.75h16.5V4.499"
                  />
                </svg>
              </div>
            </div>
          )}
          {step === 3 && (
            <div className={`flex flex-col items-left ${animationClass}`}>
              <h2 className="text-4xl pb-8 font-semibold">
                3. What is the preferred working style?
                <span className="text-black">*</span>
              </h2>
              <input
                className="border-b-2 ml-10 font-normal w-[620px] border-gray-200 text-3xl placeholder:text-gray-400 focus:border-black outline-none transition-colors duration-200"
                placeholder="Hybrid: 1-2 days a week in our office in Holborn"
                autoFocus
                value={role?.working_style || ""}
                onChange={(e) =>
                  setRole({ ...role, working_style: e.target.value })
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    nextStep(); // Triggers the next step when 'Enter' is pressed
                  }
                }}
              />
              <div className="flex items-center ">
                <button
                  onClick={() => validateStep() && nextStep()} // Only go to the next step if the validation passes
                  className={`mt-4 ml-10 text-xl w-[70px] font-bold py-2 px-4 bg-white border-2 rounded-md ${
                    validateStep()
                      ? "border-[#02B491] hover:bg-[#02B491] hover:text-white"
                      : "border-gray-300 text-gray-400 cursor-not-allowed"
                  }`}
                  disabled={!validateStep()} // Disable the button if the step is invalid
                >
                  OK
                </button>
                <h className="text-sm ml-4 mt-4">
                  press <span className="font-bold">Enter</span>
                </h>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-5 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m7.49 12-3.75 3.75m0 0 3.75 3.75m-3.75-3.75h16.5V4.499"
                  />
                </svg>
              </div>
            </div>
          )}
          {step === 4 && (
            <div className={`flex flex-col items-left ${animationClass}`}>
              <h2 className="text-4xl pb-8 font-semibold">
                4. Are you open to offering equity?
                <span className="text-black">*</span>
              </h2>
              <div className="ml-10">
                <button
                  className={`mb-2 px-2 text-left flex items-center text-2xl w-[200px] py-2 rounded-md border  border-[#02B491] bg-[#02B491]/10 ${
                    selectedAnswers.q4 === "Yes" ? "border-2" : ""
                  }`}
                  onClick={() => handleSelectAnswer("q4", "Yes")}
                >
                  <span
                    className={`rounded-sm text-base px-2 font-bold mr-2 border border-[#02B491] ${
                      selectedAnswers.q4 === "Yes"
                        ? "bg-[#02B491] text-white"
                        : "bg-white/50"
                    }`}
                  >
                    Y
                  </span>
                  Yes
                  {selectedAnswers.q4 === "Yes" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      className="ml-auto w-5 h-5 text-[#02B491]"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  )}
                </button>
                <button
                  className={`px-2 text-left flex items-center text-2xl w-[200px] hover:bg-gray-600/20 py-2 rounded-md border border-gray-600 bg-gray-600/10 ${
                    selectedAnswers.q4 === "No"
                      ? "border-2 "
                      : "border-gray-600"
                  }`}
                  onClick={() => handleSelectAnswer("q4", "No")}
                >
                  <span
                    className={`rounded-sm text-base px-2 font-bold mr-2 border border-gray-600  ${
                      selectedAnswers.q4 === "No"
                        ? "bg-gray-600 text-white"
                        : "bg-white/50"
                    }`}
                  >
                    N
                  </span>
                  No
                  {selectedAnswers.q4 === "No" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="black"
                      className="ml-auto w-5 h-5 text-[#02B491]"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  )}
                </button>
              </div>

              <div className="flex items-center ">
                <button
                  onClick={() => validateStep() && nextStep()} // Only go to the next step if the validation passes
                  className={`mt-4 ml-10 text-xl w-[70px] font-bold py-2 px-4 bg-white border-2 rounded-md ${
                    validateStep()
                      ? "border-[#02B491] hover:bg-[#02B491] hover:text-white"
                      : "border-gray-300 text-gray-400 cursor-not-allowed"
                  }`}
                  disabled={!validateStep()} // Disable the button if the step is invalid
                >
                  OK
                </button>
              </div>
            </div>
          )}

          {step === 20 && (
            <div className={`flex flex-col items-left ${animationClass}`}>
              <h2 className="text-4xl  font-semibold">
                5. When would you like to receive applicants?
                <span className="text-black">*</span>
              </h2>
              <p className="italic text-black pb-8 pl-10">
                We share roles with candidates every other week and pass on
                interested applicants for you to review on the following Monday.
              </p>
              <div className="ml-10">
                <button
                  className={`mb-2 px-2 text-left flex items-center text-2xl w-[350px] py-2 rounded-md border  border-[#02B491] bg-[#02B491]/10 hover:bg-[#02B491]/20 ${
                    selectedAnswers.q5 === "Monday 18th October"
                      ? "border-2"
                      : ""
                  }`}
                  onClick={() =>
                    handleSelectAnswer("q5", "Monday 18th October")
                  }
                >
                  <span
                    className={`rounded-sm text-base px-2 font-bold mr-2 border border-[#02B491] ${
                      selectedAnswers.q5 === "Monday 18th October"
                        ? "bg-[#02B491] text-white"
                        : "bg-white/50"
                    }`}
                  >
                    1
                  </span>
                  Monday 18<sup>th</sup> November
                  {selectedAnswers.q5 === "Monday 18th October" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      className="ml-auto w-5 h-5 text-[#02B491]"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  )}
                </button>

                <button
                  className={`mb-2 px-2 text-left flex items-center text-2xl w-[350px] py-2 rounded-md border  border-gray-600 bg-gray-600/10 hover:bg-gray-600/20 ${
                    selectedAnswers.q5 === "Maybe later" ? "border-2" : ""
                  }`}
                  onClick={() => handleSelectAnswer("q5", "Maybe later")}
                >
                  <span
                    className={`rounded-sm text-base px-2 font-bold mr-2 border border-gray-600 ${
                      selectedAnswers.q5 === "Maybe later"
                        ? "bg-gray-600 text-white"
                        : "bg-white/50"
                    }`}
                  >
                    M
                  </span>
                  Maybe later
                  {selectedAnswers.q5 === "Maybe later" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="black"
                      className="ml-auto w-5 h-5 text-[#02B491]"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  )}
                </button>
              </div>

              <div className="flex items-center ">
                <button
                  onClick={() => validateStep() && nextStep()} // Only go to the next step if the validation passes
                  className={`mt-4 ml-8 text-xl w-[70px] font-bold py-2 px-4 bg-white border-2 rounded-md ${
                    validateStep()
                      ? "border-[#02B491] hover:bg-[#02B491] hover:text-white"
                      : "border-gray-300 text-gray-400 cursor-not-allowed"
                  }`}
                  disabled={!validateStep()} // Disable the button if the step is invalid
                >
                  OK
                </button>
              </div>
            </div>
          )}

          {step === 5 && (
            <div className={`flex flex-col items-left ${animationClass}`}>
              <div className="flex">
                <h2 className="text-4xl font-semibold">5. </h2>
                <h2 className="text-4xl pb-8 font-semibold">
                  Which candidate profiles would suit this role best? (select
                  all that apply)<span className="text-black">*</span>
                </h2>
              </div>

              <div className="ml-8">
                <button
                  className={`mb-2 px-2 text-left flex items-center text-2xl w-[300px] py-2 rounded-md border border-green-500 bg-green-500/10 ${
                    selectedAnswers.q5.includes("Generalist") ? "border-2 " : ""
                  }`}
                  onClick={() => handleSelectAnswer("q5", "Generalist")}
                >
                  <span
                    className={`rounded-sm text-base px-2 font-bold mr-2 border border-green-500 ${
                      selectedAnswers.q5.includes("Generalist")
                        ? "bg-green-500 text-white"
                        : "bg-white/50"
                    }`}
                  >
                    G
                  </span>
                  Generalist
                  {selectedAnswers.q5.includes("Generalist") && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      className="ml-auto w-5 h-5 text-green-600"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  )}
                </button>
                <button
                  className={`mb-2 px-2 text-left flex items-center text-2xl w-[300px] py-2 rounded-md border border-blue-600 bg-blue-600/10 ${
                    selectedAnswers.q5.includes("Sales") ? "border-2 " : ""
                  }`}
                  onClick={() => handleSelectAnswer("q5", "Sales")}
                >
                  <span
                    className={`rounded-sm text-base px-2 font-bold mr-2 border border-blue-500 ${
                      selectedAnswers.q5.includes("Sales")
                        ? "bg-blue-500 text-white"
                        : "bg-white/50"
                    }`}
                  >
                    S
                  </span>
                  Sales
                  {selectedAnswers.q5.includes("Sales") && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      className="ml-auto w-5 h-5 text-blue-600"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  )}
                </button>
                <button
                  className={`mb-2 px-2 text-left flex items-center text-2xl w-[300px] py-2 rounded-md border border-purple-600 bg-purple-600/10 ${
                    selectedAnswers.q5.includes("Marketing") ? "border-2 " : ""
                  }`}
                  onClick={() => handleSelectAnswer("q5", "Marketing")}
                >
                  <span
                    className={`rounded-sm text-base px-2 font-bold mr-2 border border-purple-500 ${
                      selectedAnswers.q5.includes("Marketing")
                        ? "bg-purple-500 text-white"
                        : "bg-white/50"
                    }`}
                  >
                    M
                  </span>
                  Marketing
                  {selectedAnswers.q5.includes("Marketing") && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      className="ml-auto w-5 h-5 text-purple-600"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  )}
                </button>
                <button
                  className={`px-2 text-left flex items-center text-2xl w-[300px] py-2 rounded-md border border-orange-600 bg-orange-600/10 ${
                    selectedAnswers.q5.includes("Software Engineering")
                      ? "border-2 "
                      : ""
                  }`}
                  onClick={() =>
                    handleSelectAnswer("q5", "Software Engineering")
                  }
                >
                  <span
                    className={`rounded-sm text-base px-2 font-bold mr-2 border border-orange-500 ${
                      selectedAnswers.q5.includes("Software Engineering")
                        ? "bg-orange-500 text-white"
                        : "bg-white/50"
                    }`}
                  >
                    E
                  </span>
                  Software Engineering
                  {selectedAnswers.q5.includes("Software Engineering") && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      className="ml-auto w-5 h-5 text-orange-600"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  )}
                </button>
              </div>

              <div className="flex items-center ">
                <button
                  onClick={() => validateStep() && nextStep()} // Only go to the next step if the validation passes
                  className={`mt-4 ml-8 text-xl w-[70px] font-bold py-2 px-4 bg-white border-2 rounded-md ${
                    validateStep()
                      ? "border-[#02B491] hover:bg-[#02B491] hover:text-white"
                      : "border-gray-300 text-gray-400 cursor-not-allowed"
                  }`}
                  disabled={!validateStep()} // Disable the button if the step is invalid
                >
                  OK
                </button>
                <h className="text-xs ml-2 mt-4">
                  press <span className="font-bold">Enter</span>
                </h>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-5 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m7.49 12-3.75 3.75m0 0 3.75 3.75m-3.75-3.75h16.5V4.499"
                  />
                </svg>
              </div>
            </div>
          )}
          {step === 6 && (
            <div className={`flex flex-col items-left ${animationClass}`}>
              <h2 className="text-4xl font-semibold">
                6. What is the name of this role's hiring lead?
                <span className="text-black">*</span>
              </h2>
              <p className="text-black italic pl-10 pb-8">
                All email communications will be sent to this person going
                forward!
              </p>
              <input
                className="border-b-2 ml-10 font-normal w-[620px] border-gray-200 text-3xl placeholder:text-gray-400 focus:border-black outline-none transition-colors duration-200"
                placeholder="John Smith"
                autoFocus
                value={role?.hiring_lead_name || ""}
                onChange={(e) =>
                  setRole({ ...role, hiring_lead_name: e.target.value })
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    nextStep(); // Triggers the next step when 'Enter' is pressed
                  }
                }}
              />
              <div className="flex items-center ">
                <button
                  onClick={() => validateStep() && nextStep()} // Only go to the next step if the validation passes
                  className={`mt-4 ml-10 text-xl w-[70px] font-bold py-2 px-4 bg-white border-2 rounded-md ${
                    validateStep()
                      ? "border-[#02B491] hover:bg-[#02B491] hover:text-white"
                      : "border-gray-300 text-gray-400 cursor-not-allowed"
                  }`}
                  disabled={!validateStep()} // Disable the button if the step is invalid
                >
                  OK
                </button>
                <h className="text-sm ml-4 mt-4">
                  press <span className="font-bold">Enter</span>
                </h>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-5 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m7.49 12-3.75 3.75m0 0 3.75 3.75m-3.75-3.75h16.5V4.499"
                  />
                </svg>
              </div>
            </div>
          )}
          {step === 7 && (
            <div className={`flex flex-col items-left ${animationClass}`}>
              <h2 className="text-4xl pb-8 font-semibold">
                7. What is their email address?{" "}
                <span className="text-black">*</span>
              </h2>
              <input
                className="border-b-2 ml-10 font-normal w-[620px] border-gray-200 text-3xl placeholder:text-gray-400 focus:border-black outline-none transition-colors duration-200"
                placeholder="jsmith@jumpstart-uk.com"
                autoFocus
                value={role?.hiring_lead_email || ""}
                onChange={(e) =>
                  setRole({ ...role, hiring_lead_email: e.target.value })
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    nextStep(); // Triggers the next step when 'Enter' is pressed
                  }
                }}
              />
              <div className="flex items-center ">
                <button
                  onClick={() => validateStep() && nextStep()} // Only go to the next step if the validation passes
                  className={`mt-4 ml-10 text-xl w-[70px] font-bold py-2 px-4 bg-white border-2 rounded-md ${
                    validateStep()
                      ? "border-[#02B491] hover:bg-[#02B491] hover:text-white"
                      : "border-gray-300 text-gray-400 cursor-not-allowed"
                  }`}
                  disabled={!validateStep()} // Disable the button if the step is invalid
                >
                  OK
                </button>
                <h className="text-sm ml-4 mt-4">
                  press <span className="font-bold">Enter</span>
                </h>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-5 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m7.49 12-3.75 3.75m0 0 3.75 3.75m-3.75-3.75h16.5V4.499"
                  />
                </svg>
              </div>
            </div>
          )}
          {step === 8 && (
            <div className={`flex flex-col items-left ${animationClass}`}>
              <h2 className="text-4xl pb-8 font-semibold">
                8. What is their linkedin?
              </h2>
              <input
                className="border-b-2 ml-10 font-normal w-[620px] border-gray-200 text-3xl placeholder:text-gray-400 focus:border-black outline-none transition-colors duration-200"
                placeholder="https://linkedin.com/in/john-smith/"
                autoFocus
                value={role?.hiring_lead_linkedin || ""}
                onChange={(e) =>
                  setRole({ ...role, hiring_lead_linkedin: e.target.value })
                }
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    nextStep(); // Triggers the next step when 'Enter' is pressed
                  }
                }}
              />
              <div className="flex items-center ">
                <button
                  onClick={() => nextStep()}
                  className="mt-4 ml-10 text-xl w-[70px] font-bold py-2 px-4 bg-white border-2 border-[#02B491] rounded-md hover:bg-[#02B491] hover:text-white"
                >
                  OK
                </button>{" "}
                <h className="text-sm ml-4 mt-4">
                  press <span className="font-bold">Enter</span>
                </h>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-5 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m7.49 12-3.75 3.75m0 0 3.75 3.75m-3.75-3.75h16.5V4.499"
                  />
                </svg>
              </div>
            </div>
          )}
          {step === 9 && (
            <div className={`flex flex-col items-left ${animationClass}`}>
              <h2 className="text-4xl  font-semibold">
                9. Please upload a job description
              </h2>
              <p className="italic pb-8 pl-10">
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open(
                      "https://jumpstart-alumni.notion.site/Writing-a-Job-Description-33504887841241d0a52f5a2a9e23e245"
                    );
                  }}
                  className="text-blue-500 hover:underline cursor-pointer"
                >
                  Click here
                </span>{" "}
                for job description guidance
              </p>

              {/* Choose between File or Link */}
              <div className="ml-10">
                <button
                  className={`mb-2 px-2 text-left flex items-center text-2xl w-[300px] py-2 rounded-md border border-blue-600 bg-blue-600/10 ${
                    uploadChoice === "link" ? "border-2 " : ""
                  }`}
                  onClick={() => setUploadChoice("link")}
                >
                  <span
                    className={`rounded-sm text-base px-2 font-bold mr-2 border border-blue-500 ${
                      uploadChoice === "link"
                        ? "bg-blue-500 text-white"
                        : "bg-white/50"
                    }`}
                  >
                    L
                  </span>
                  Share a Link
                  {uploadChoice === "link" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      className="ml-auto w-5 h-5 text-blue-600"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  )}
                </button>
                <button
                  className={`mb-2 px-2 text-left flex items-center text-2xl w-[300px] py-2 rounded-md border border-green-500 bg-green-500/10 ${
                    uploadChoice === "file" ? "border-2 " : ""
                  }`}
                  onClick={() => setUploadChoice("file")}
                >
                  <span
                    className={`rounded-sm text-base px-2 font-bold mr-2 border border-green-500 ${
                      uploadChoice === "file"
                        ? "bg-green-500 text-white"
                        : "bg-white/50"
                    }`}
                  >
                    F
                  </span>
                  Upload a File
                  {uploadChoice === "file" && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      className="ml-auto w-5 h-5 text-green-600"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  )}
                </button>
              </div>

              {/* Render file upload or link input based on the user's choice */}
              {uploadChoice === "file" && (
                <div className="flex flex-col items-start ml-10">
                  <div className="flex items-center justify-center w-full">
                    <label
                      htmlFor="dropzone-file"
                      className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                    >
                      {isUploading ? (
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <div className="mt-4 flex items-center justify-center p-4 bg-gray-100 text-gray-700 rounded-lg w-full max-w-[500px]">
                            <p className="mr-4">Uploading...</p>
                            <div className="relative w-full h-2 bg-gray-300 rounded-full overflow-hidden">
                              <div className="absolute left-0 top-0 h-full w-1/3 bg-gray-500 rounded-full animate-move"></div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <svg
                            className="w-8 h-8 mb-4 text-gray-500"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 16"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                            />
                          </svg>

                          <p className="mb-2 text-sm text-gray-500">
                            <span className="font-semibold">
                              Click to upload
                            </span>{" "}
                            or drag and drop
                          </p>
                          <p className="text-xs text-gray-500">
                            PDF, DOCX, (MAX. 10MB)
                          </p>
                        </div>
                      )}

                      <input
                        id="dropzone-file"
                        type="file"
                        className="hidden"
                        onChange={(e) => {
                          setFile(e.target.files[0]); // Set file state
                          handleUpload(e.target.files[0]); // Trigger the upload immediately after file selection
                        }}
                      />
                    </label>
                  </div>

                  {/* Display the upload status message */}
                  {/* Display the upload status message */}
                  {message && (
                    <div className="mt-4 flex items-center p-4 bg-gray-100 text-gray-700 rounded-lg w-full max-w-[300px]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        className="w-6 h-6 text-gray-500 mr-3"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                      <p>{message}</p>
                    </div>
                  )}
                </div>
              )}

              {uploadChoice === "link" && (
                <input
                  className="border-b-2 pt-4 ml-10 font-normal w-[680px] border-gray-200 text-3xl placeholder:text-gray-400 focus:border-black outline-none transition-colors duration-200"
                  placeholder="https://www.jumpstart-uk.com/operations-associate"
                  autoFocus
                  value={role.job_description_link}
                  onChange={(e) =>
                    updateRoleField("job_description_link", e.target.value)
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      nextStep(); // Triggers the next step when 'Enter' is pressed
                    }
                  }}
                />
              )}

              <div className="flex items-center ">
                <button
                  onClick={() => nextStep()}
                  className="mt-4 ml-10 text-xl font-bold py-2 px-4 bg-white border-2 border-[#02B491] rounded-md hover:bg-[#02B491] hover:text-white"
                >
                  {isFieldEmpty() ? "skip for now" : "OK"}
                </button>
                <h className="text-sm ml-4 mt-4">
                  press <span className="font-bold">Enter</span>
                </h>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="size-5 mt-2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="m7.49 12-3.75 3.75m0 0 3.75 3.75m-3.75-3.75h16.5V4.499"
                  />
                </svg>
              </div>
            </div>
          )}

          {step === 10 && (
            <div className={`flex flex-col items-left ${animationClass}`}>
              <div className="flex">
                <h2 className="text-4xl pr-2  font-semibold">10.</h2>
                <h2 className="text-4xl pb-8 font-semibold">
                  Are there any essential skills or experiences needed for this
                  role?
                </h2>
              </div>

              <textarea
                className="border rounded-md ml-14 font-normal w-[700px] border-gray-200 text-xl placeholder:text-gray-400 outline-none transition-colors duration-200"
                placeholder="Need to have: awesome proactivity, ability to learn fast, strong communication skills / Nice to have: STEM degree, understanding of SQL"
                autoFocus
                value={role?.key_experience || ""}
                onChange={(e) =>
                  setRole({ ...role, key_experience: e.target.value })
                }
                rows={6} // Sets the height to 5 lines
              />

              <div className="flex items-center ">
                <button
                  onClick={() => nextStep()}
                  className="mt-4 ml-14 text-xl w-[70px] font-bold py-2 px-4 bg-white border-2 border-[#02B491] rounded-md hover:bg-[#02B491] hover:text-white"
                >
                  OK
                </button>{" "}
              </div>
            </div>
          )}
          {step === 11 && (
            <div className={`flex flex-col items-left ${animationClass}`}>
              <h2 className="text-4xl pb-8 font-semibold">
                11. What is the interview process?
              </h2>
              <textarea
                className="border rounded-md ml-14 font-normal w-[700px] border-gray-200 text-xl placeholder:text-gray-400 outline-none transition-colors duration-200"
                placeholder="3 rounds: 1) Skill / General Fit interview with hiring lead, 2) Take home case study, 3) Final round with the hiring lead and Founder."
                autoFocus
                value={role?.interview_process || ""}
                onChange={(e) =>
                  setRole({ ...role, interview_process: e.target.value })
                }
                rows={6} // Sets the height to 5 lines
              />

              <div className="flex items-center ">
                <button
                  onClick={() => nextStep()}
                  className="mt-4 ml-14 text-xl w-[70px] font-bold py-2 px-4 bg-white border-2 border-[#02B491] rounded-md hover:bg-[#02B491] hover:text-white"
                >
                  OK
                </button>{" "}
              </div>
            </div>
          )}
          {step === 12 && (
            <VideoRecording
              spotlights={spotlights}
              selectedSpotlight={selectedSpotlight}
              setSelectedSpotlight={setSelectedSpotlight}
              role={role}
              setRole={setRole}
              handleSaveRole={handleSaveRole}
              setSpotlightNeedUpdate={setSpotlightNeedUpdate}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default NewRole;
